/// <reference types="../material-ui" />

import { gtagAddToCart } from '@graphcommerce/googleanalytics'
import {
  responsiveVal,
  breakpointVal,
  MuiButtonPill,
  MuiButtonResponsive,
  themeBaseDefaults,
  MuiSnackbar,
  MuiFabSizes,
  MuiSlider,
  MuiChip,
  MuiButtonInline,
  NextLink,
} from '@graphcommerce/next-ui'
import { createTheme, Theme, alpha, darken, LinkProps } from '@mui/material'
import { Components, PaletteOptions } from '@mui/material/styles'

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#628799',
    contrastText: '#ffffff',
    dark: '#506e7e',
  },
  secondary: {
    main: '#232529',
    light: '#e3e3e3',
    contrastText: '#ffffff',
  },
  darkContrast: {
    main: '#ffffff',
    contrastText: '#151515',
    light: '#ffffff',
    dark: '#ffffff',
  },
  background: {
    default: '#FFFFFF',
    dark: '#000000',
    paper: '#d6e0e3',
    brand: '#628799',
    brandDark: '#2F3238',
    lightContrast: '#F2F2F2',
    image: '#ffffff',
  },
  divider: '#00000015',
  success: {
    main: '#01d26a',
  },
  action: {
    hoverOpacity: 0.16,
  },
  text: {
    primary: '#222222',
    contrast: '#ffffff',
    secondary: '#817f7f',
    disabled: '#00000030',
    grey: '#666464',
  },
}

const darkPalette = lightPalette

const fontSize = (from: number, to: number) =>
  breakpointVal('fontSize', from, to, themeBaseDefaults.breakpoints.values)

const font = {
  sansSerif: 'century-gothic,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
  serif: 'mencken-std,Georgia,serif,Apple Color Emoji,Segoe UI Emoji',
}

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette,
    ...themeBaseDefaults,
    breakpoints: {
      values: {
        xs: 0,
        slim: 470,
        sm: 550,
        md: 920,
        mdlg: 1200,
        lg: 1560,
        xl: 1780,
      },
    },
    shape: { borderRadius: 3 },
    typography: {
      fontFamily: font.sansSerif,
      fontWeightBold: 600,
      // @see docs typography.md
      h1: {
        ...fontSize(28, 48),
        fontWeight: 400,
        fontVariationSettings: "'wght' 660",
        lineHeight: 1.22,
        fontFamily: font.serif,
      },
      h2: {
        ...fontSize(24, 40),
        lineHeight: 1.35,
        fontFamily: font.serif,
      },
      h3: {
        ...fontSize(21, 30),
        lineHeight: 1.55,
        fontFamily: font.serif,
      },
      h4: {
        ...fontSize(17, 26),
        lineHeight: 1.55,
        fontFamily: font.serif,
      },
      h5: {
        ...fontSize(17, 20),
        lineHeight: 1.55,
        fontFamily: font.serif,
      },
      h6: {
        ...fontSize(17, 20),
        lineHeight: 1.8,
        fontFamily: font.serif,
      },
      subtitleLarge: {
        ...fontSize(22, 26),
        lineHeight: 1.7,
      },
      subtitle1: {
        ...fontSize(16, 19),
        lineHeight: 1.7,
        fontFamily: font.serif,
      },
      bodyLarge: {
        ...fontSize(17, 20),
        lineHeight: 1.7,
        fontFamily: font.sansSerif,
      },
      body1: {
        ...fontSize(14, 18),
        lineHeight: 1.7,
        fontFamily: font.sansSerif,
      },
      subtitle2: {
        ...fontSize(14, 16),
        lineHeight: 1.7,
        fontWeight: 500,
        fontVariationSettings: "'wght' 520",
      },
      body2: {
        ...fontSize(13, 15),
        lineHeight: 1.7,
        fontFamily: font.sansSerif,
      },
      price: {
        ...fontSize(24, 28),
        lineHeight: 1.7,
        fontFamily: font.sansSerif,
        fontWeight: 400,
      },
      caption: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 13),
        lineHeight: 1.5,
      },
      button: {
        fontFamily: font.sansSerif,
        fontWeight: 400,
      },
      overline: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 14),
        fontWeight: 500,
        letterSpacing: 1,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      },
    },
    spacings: {
      xxxxs: responsiveVal(3, 5),
      xxxs: responsiveVal(5, 10),
      xxs: responsiveVal(10, 16),
      xs: responsiveVal(12, 20),
      sm: responsiveVal(14, 30),
      md: responsiveVal(16, 50),
      lg: responsiveVal(24, 80),
      xl: responsiveVal(40, 100),
      xxl: responsiveVal(80, 160),
    },
    page: {
      horizontal: 'var(--page-horizontal)', // responsiveVal(10, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: 'auto',
      headerHeightMd: 'auto',
      appBarHeightMd: 'auto',
      appBarInnerHeightMd: '46px',
    },
  })

// todo: move most of the styles to the graphcommerce library while still allowing for extensibility.
const createOverrides = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        '--page-horizontal': '15px',
        overflowY: 'scroll',

        [theme.breakpoints.up('lg')]: {
          '--page-horizontal': '30px',
        },

        '&.nav-open': {
          overflowX: 'hidden',
        },
      },
      '::selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '::-moz-selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '#__next': {
        position: 'relative',
      },
      'picture img': {
        filter: 'brightness(1.03)',
        willChange: 'filter',
      },
      '.MuiContainer-root.container-negative': {
        marginLeft: `calc(${theme.page.horizontal} * -1)`,
        marginRight: `calc(${theme.page.horizontal} * -1)`,
        paddingLeft: 0,
        paddingRight: 0,
      },
      '.visually-hidden': {
        border: '0',
        clipPath: 'rect(0, 0, 0, 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: '0',
        position: 'absolute',
        width: '1px',
      },
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: { defaultProps: { component: NextLink } as LinkProps },
  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },

  MuiContainer: {
    variants: [
      {
        props: { disableGutters: false },
        style: {
          paddingLeft: theme.page.horizontal,
          paddingRight: theme.page.horizontal,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px', // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },

  MuiButton: {
    defaultProps: {
      color: 'inherit',

      sx: {
        ...theme.typography.body1,
        textTransform: 'uppercase',
        borderRadius: 0,
      },
    },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonInline,
      {
        props: { variant: 'pill' },
        style: {},
      },
      {
        props: { variant: 'pill', size: 'small' },
        style: {
          // '&:not(.Mui-disabled)': { boxShadow: theme.shadows[2] },
        },
      },
      {
        props: { variant: 'pill', size: 'medium' },
        style: {
          // '&:not(.Mui-disabled)': { boxShadow: theme.shadows[4] },
        },
      },
      {
        props: { variant: 'pill', size: 'large' },
        style: {
          // '&:not(.Mui-disabled)': { boxShadow: theme.shadows[6] },
        },
      },
      {
        props: { variant: 'pill', disableElevation: true },
        style: { boxShadow: 'none' },
      },
      {
        props: { variant: 'pill', color: 'primary' },
        style: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover:not(.Mui-disabled)': { backgroundColor: theme.palette.primary.dark },
        },
      },
      {
        props: { variant: 'pill', color: 'secondary' },
        style: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover:not(.Mui-disabled)': { backgroundColor: theme.palette.secondary.dark },
        },
      },
      {
        props: { variant: 'pill', color: 'inherit' },
        style: { backgroundColor: theme.palette.background.brand },
      },
      {
        props: { variant: 'pill', disabled: true },
        style: {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
        },
      },
      {
        props: { variant: 'contained' },
        style: {
          borderRadius: 0,
          backgroundColor: theme.palette.background.brand,
          color: theme.palette.text.contrast,

          '&:hover:not(.Mui-disabled)': {
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.contrast,
            textDecoration: 'none',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          borderRadius: 0,
          backgroundColor: theme.palette.background.dark,
          color: theme.palette.text.contrast,
          border: `1px solid ${theme.palette.background.dark}`,
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.background.dark,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'darkContrast' },
        style: {
          borderRadius: 0,
          backgroundColor: theme.palette.darkContrast.main,
          color: theme.palette.darkContrast.contrastText,
        },
      },
      {
        props: { variant: 'outlined' },
        style: {},
      },
      {
        props: { variant: 'text' },
        style: {
          textDecoration: 'underline',
          color: 'inherit',
          '& a': {
            color: 'inherit',
          },
          '&:hover:not(.Mui-disabled)': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
        },
      },
      {
        props: { variant: 'inline' },
        style: {},
      },
      {
        props: { color: 'primary' },
        style: {
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
    ],
  },

  MuiIconButton: {
    variants: [
      {
        props: { size: 'small' },
        style: {
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    ],
  },

  MuiMenu: {
    variants: [
      {
        props: {},
        style: {
          '& .MuiMenu-list': {
            background: theme.palette.background.lightContrast,
          },
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.brand,
        '&:hover': {
          backgroundColor: theme.palette.background.brand,
        },
        color: theme.palette.text.primary,
      },
      colorInherit: {
        backgroundColor: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: 'inherit',
        },
        boxShadow: 'none',
      },
      primary: {
        color: theme.palette.text.primary,
      },
      secondary: {
        color: theme.palette.text.primary,
      },
      extended: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },

    variants: [...MuiFabSizes],
  },

  MuiOutlinedInput: {
    variants: [
      {
        props: { color: 'darkContrast' },
        style: {
          borderRadius: 0,
          color: theme.palette.darkContrast.main,
          border: `2px solid ${theme.palette.darkContrast.main}`,
        },
      },
    ],
  },

  MuiAccordion: {
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          background: 'transparent',
          color: theme.palette.background.brand,
          border: `1px solid ${theme.palette.background.brand}`,
        },
      },
    ],
  },

  // MuiTextField: {
  //   defaultProps: { color: 'secondary' },
  //   styleOverrides: {
  //   },
  // },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiChip: {
    variants: [
      {
        props: {},
        style: { '& .MuiChip-icon': { fontSize: '1.3em' } },
      },
      {
        props: { size: 'small' },
        style: {
          typography: 'caption',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          height: responsiveVal(26, 30),
          paddingLeft: responsiveVal(3, 6),
          paddingRight: responsiveVal(3, 6),
          typography: 'caption',
        },
      },
      {
        props: { size: 'responsive' },
        style: {
          height: responsiveVal(32, 40),
          paddingLeft: responsiveVal(4, 8),
          paddingRight: responsiveVal(4, 8),
          borderRadius: 0,
          typography: 'body2',
          '& .MuiChip-label': {
            paddingLeft: responsiveVal(6, 10),
            paddingRight: responsiveVal(6, 10),
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.background.default,
          '&:active': {
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: theme.palette.text.primary,
          },
          '&.MuiChip-clickable:hover': {
            backgroundColor: darken(theme.palette.background.default, 0.05),
          },
          '& .MuiChip-deleteIcon:hover': {
            color: theme.palette.text.primary,
          },
        },
      },
      {
        props: { color: 'primary' },
        style: {
          borderColor: theme.palette.text.primary,
          color: theme.palette.text.primary,
          '&:hover': {
            background: `${theme.palette.background.default} !important`,
            borderColor: `${theme.palette.divider} !important`,
          },
          '&:focus': {
            background: `${theme.palette.background.brand} !important`,
          },
          '& .MuiChip-deleteIcon:hover': {
            color: theme.palette.text.primary,
          },
        },
      },
    ],
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },

    variants: [
      {
        props: { size: 'medium' },
        style: {
          padding: 7,
        },
      },
    ],
  },

  MuiSwitch: {
    styleOverrides: {
      thumb: {
        boxShadow: theme.shadows[6],
      },
    },
  },

  MuiSnackbar: { variants: [...MuiSnackbar] },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  MuiSlider: { variants: [...MuiSlider] },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },

  AddProductsToCartForm: {
    defaultProps: {
      redirect: 'added',
      onComplete: gtagAddToCart,
    },
  },
})

export const lightTheme = createThemeWithPalette(lightPalette)
lightTheme.components = createOverrides(lightTheme)

export const darkTheme = createThemeWithPalette(darkPalette)
darkTheme.components = createOverrides(darkTheme)
